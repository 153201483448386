module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-S2MLDBMJ9K"],"gtagConfig":{"optimize_id":"GTM-W2L7DRM4","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rocco's E-books | Buy online | Publishing solutions","short_name":"Rocco's E-books","description":"Buy online e-books | Rocco offers also self-publishing solutions","lang":"en","display":"standalone","icon":"src/images/icon.png","start_url":"/","background_color":"#663399","theme_color":"#fff","localize":[{"start_url":"/de/","lang":"de","name":"Die coole Anwendung","short_name":"Coole Anwendung","description":"Die Anwendung macht coole Dinge und macht Ihr Leben besser."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6350b2f4a33a6c35f8897a6fb68d1c24"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es"],"defaultLanguage":"en","siteUrl":"https://ebooks.rocco.me","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
